<template>
    <IFrame :src="url"/>
</template>
<script setup lang="ts">
    import IFrame from "o365.vue.components.Iframe.vue";
    import {computed} from "vue";
    import Url from "o365.modules.url.ts";

    export interface Props {
        fileName: string,
        primKey: string,
        viewName: string,
        id: number
    };

    const props = defineProps<Props>();
    const url = computed(() => { return `/nt/foxit9?params=${Url.decodObject(props)}`})
    //const url = ref(`/foxit9?param=${Url.decodObject(props)}`); 
</script>